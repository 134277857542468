import { yupResolver } from '@hookform/resolvers/yup';
import { LessonsDto } from 'dto';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export default function useValidation() {
  const formSchema = Yup.object().shape({
    name: Yup.string().required('Name is required !'),
    note: Yup.string().optional().nullable(),
    from: Yup.string().optional().nullable(),
    to: Yup.string().optional().nullable(),
    isSubscription: Yup.boolean().default(false),
    isVisibleOnApp: Yup.boolean().default(false),
    haveContent: Yup.boolean().default(false),
    courseID: Yup.string().required('Course is required !')
    // keywordID: Yup.string().required('Keyword is required !')
  });

  const methods = useForm<LessonsDto>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      note: '',
      isSubscription: false,
      isVisibleOnApp: false,
      courseID: '',
      linkedLessonID: null
      // keywordID: ''
    }
  });

  return { methods };
}
