/**
 * Cont Type
 * - `A` ARTICLE
 * - `B` BOOK
 * - `C` PEOPLE
 * - `E` PEOPLE_GROUP
 * - `G` GRAPHIC
 * - `L` ALBUM
 * - `M` MEDIA
 * - `O` AUDIO
 * - `P` PHOTO
 * - `R` REPORT
 * - `T` TABLE
 * - `X` EXTERNAL_ARTICLE
 */
export enum EContType {
  ARTICLE = 'A',
  BOOK = 'B',
  PEOPLE = 'C',
  PEOPLE_GROUP = 'E',
  GRAPHIC = 'G',
  ALBUM = 'L',
  MEDIA = 'M',
  AUDIO = 'O',
  PHOTO = 'P',
  REPORT = 'R',
  TABLE = 'T',
  EXTERNAL_ARTICLE = 'X'
}

export enum FormatUpload {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf'
  // add more to need
}
