import { Box, alpha } from '@mui/material';
import { FC, ReactNode } from 'react';

// ────────────────────────────────────────────

export interface SnackbarIconProps {
  icon: ReactNode;
  color: string;
}

// ────────────────────────────────────────────

const SnackbarIcon: FC<SnackbarIconProps> = ({ icon, color }) => {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.25,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16)
      }}
    >
      {icon}
    </Box>
  );
};

export default SnackbarIcon;
