import { Box, Typography } from '@mui/material';
import ImageGallery from './ImageGallery';
import FileUploadCommon from './uploadFile';

function UploadPreviewImage({
  label,
  image,
  onUpload,
  onDelete,
  loading,
  textErr,
  width,
  height
}: {
  label?: string;
  image: string;
  onUpload: (files?: FileList) => void;
  onDelete?: () => void;
  loading?: boolean;
  textErr?: string;
  width?: string;
  height?: string;
}) {
  return (
    <Box
      width={width || '100%'}
      height={height || '150px'}
      sx={{ marginBottom: textErr ? '40px' : '10px' }}
    >
      {label && (
        <Typography variant="subtitle1" gutterBottom>
          {label}
        </Typography>
      )}
      {image ? (
        <ImageGallery src={image} useDelete onChange={onDelete} />
      ) : (
        <FileUploadCommon
          onChange={onUpload}
          isSingleFile
          title={'Upload Image'}
          loading={loading}
        />
      )}
      {textErr ? (
        <Box
          mt="3px"
          color="#FF1943"
          lineHeight="1.66"
          fontSize="13px"
          fontWeight="bold"
        >
          {textErr}
        </Box>
      ) : undefined}
    </Box>
  );
}

export default UploadPreviewImage;
