import {
  CheckCircleRounded,
  CloseRounded,
  ErrorRounded,
  InfoRounded,
  WarningRounded
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { ReactNode, useRef } from 'react';
import SnackbarIcon from './SnackbarIcon';
import SnackbarStyles from './SnackbarStyles';

// ────────────────────────────────────────────

interface NotistackProviderProps {
  children: ReactNode;
}

export default function NotistackProvider({
  children
}: NotistackProviderProps) {
  const notistackRef = useRef<SnackbarProvider>(null);

  const onClose = (key: SnackbarKey) => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        autoHideDuration={3000}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon={<InfoRounded />} color="info" />,
          success: (
            <SnackbarIcon icon={<CheckCircleRounded />} color="success" />
          ),
          warning: <SnackbarIcon icon={<WarningRounded />} color="warning" />,
          error: <SnackbarIcon icon={<ErrorRounded />} color="error" />
        }}
        // With close as default
        action={(key: SnackbarKey) => (
          <IconButton size="small" onClick={() => onClose(key)} sx={{ p: 0.5 }}>
            <CloseRounded fontSize="small" />
          </IconButton>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
