import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, useTheme, CircularProgress } from '@mui/material';
import { FileUploadProps } from 'dto';
import React from 'react';

const FileUploadCommon: React.FC<FileUploadProps> = ({
  sx,
  children,
  onChange,
  isSingleFile,
  accept,
  title,
  loading,
  ...rest
}: FileUploadProps) => {
  const theme = useTheme();
  const fileRef = React.useRef<HTMLInputElement>(null);

  return (
    <Box
      position="relative"
      sx={{
        backgroundColor: '#fff',
        border: `2px dashed rgb(204, 204, 204)`,
        cursor: 'pointer',
        '&::before': {
          content: `''`,
          position: 'absolute',
          left: -2,
          right: -2,
          top: -2,
          bottom: -2,
          border: `2px ${theme.palette.primary.main} dashed`,
          zIndex: 2,
          pointerEvents: 'none',
          bgcolor: theme.palette.common.white,
          opacity: 0.4
        },
        ...(sx || {})
      }}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      onClick={() => fileRef.current?.click()}
      {...rest}
    >
      <input
        ref={fileRef}
        hidden
        multiple={!isSingleFile}
        type="file"
        accept={accept || 'image/*'}
        onChange={(e) => {
          const { files } = e.target;
          onChange?.(files || undefined);
          e.target.value = '';
        }}
      />
      {loading ? (
        <CircularProgress color="primary" />
      ) : children ? (
        children
      ) : (
        <>
          <AddIcon
            sx={{
              mb: 4,
              fontSize: 30,
              color: theme.palette.grey[500]
            }}
          />
          <Typography component="p" variant="caption" color="GrayText">
            {title || 'Upload File'}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default FileUploadCommon;
