import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import Preview from './content/applications/ContentsOfLesson/PreviewCards/Preview';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { AuthGuard, GuestGuard } from './guards';
import LessonInfo from 'src/content/applications/Lessons/LessonInfo';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// const Keyword = Loader(lazy(() => import('src/content/applications/Keyword')));
const Courses = Loader(lazy(() => import('src/content/applications/Courses')));
const Lessons = Loader(lazy(() => import('src/content/applications/Lessons')));

const ChildhoodPeriodsPage = Loader(
  lazy(() => import('src/content/applications/ChildhoodPeriods'))
);
const ChildhoodPeriodPage = Loader(
  lazy(
    () =>
      import('src/content/applications/ChildhoodPeriods/ChildhoodPeriodPage')
  )
);

const QuizzsPage = Loader(
  lazy(() => import('src/content/applications/Quizzs'))
);
const QuizzPage = Loader(
  lazy(() => import('src/content/applications/Quizzs/QuizzPage'))
);

const AssistantList = Loader(
  lazy(() => import('src/content/applications/assistant/list'))
);
const AssistantDetail = Loader(
  lazy(() => import('src/content/applications/assistant/detail'))
);
const ContentsOfLesson = Loader(
  lazy(() => import('src/content/applications/ContentsOfLesson'))
);

const Campaigns = Loader(
  lazy(() => import('src/content/applications/Campaigns'))
);

const SignInPage = Loader(lazy(() => import('src/content/auth/SignIn')));
const HookForm = Loader(lazy(() => import('src/content/hook-form')));

const UserList = Loader(
  lazy(() => import('src/content/applications/user/list'))
);
const UserCreateEdit = Loader(
  lazy(() => import('src/content/applications/user/create-edit'))
);
const TwoFA = Loader(lazy(() => import('src/content/auth/TwoFA')));

const ForgotPassword = Loader(
  lazy(() => import('src/content/auth/ForgotPassword'))
);

const VerifyForgotPassword = Loader(
  lazy(() => import('src/content/auth/ForgotPassword/VerifyForgotPassword'))
);

const ResetPassword = Loader(
  lazy(() => import('src/content/auth/ForgotPassword/ResetPassword'))
);

const routes: RouteObject[] = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <BaseLayout>
          <SignInPage />
        </BaseLayout>
      </GuestGuard>
    )
  },
  {
    path: 'form',
    element: <HookForm />
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="courses" replace />
      },
      {
        path: 'courses',
        element: <Courses />
      },
      {
        path: 'lesson',
        children: [
          {
            index: true,
            element: <Lessons />
          },
          {
            path: ':id',
            element: <LessonInfo />
          }
        ]
      },

      {
        path: 'childhood-periods',
        children: [
          {
            index: true,
            element: <ChildhoodPeriodsPage />
          },
          {
            path: 'create',
            element: <ChildhoodPeriodPage />
          },
          {
            path: ':id',
            element: <ChildhoodPeriodPage />
          }
        ]
      },

      {
        path: 'quizzs',
        children: [
          {
            index: true,
            element: <QuizzsPage />
          },
          {
            path: 'create',
            element: <QuizzPage />
          },
          {
            path: ':id',
            element: <QuizzPage />
          }
        ]
      },

      {
        path: 'assistant',
        children: [
          {
            index: true,
            element: <AssistantList />
          },
          {
            path: 'create',
            element: <AssistantDetail />
          },
          {
            path: ':id',
            element: <AssistantDetail />
          }
        ]
      },
      {
        path: 'contents-of-lesson',
        element: <ContentsOfLesson />
      },
      {
        path: 'user',
        children: [
          {
            index: true,
            element: <Navigate to="/user/list" />
          },
          {
            path: 'list',
            element: <UserList />
          },
          {
            path: ':id/edit',
            element: <UserCreateEdit />
          }
        ]
      },
      {
        path: 'campaigns',
        element: <Campaigns />
      }
    ]
  },
  {
    path: '/preview/:id/:token',
    element: <Preview />
  },
  {
    path: '/two-fa',
    element: <TwoFA />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/forgot-password/verify',
    element: <VerifyForgotPassword />
  },
  {
    path: '/forgot-password/reset-password',
    element: <ResetPassword />
  }
];

export default routes;
