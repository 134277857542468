import { AmsResponse } from 'ams';
import http from '../util/http';
import dto, {
  AuthDTO,
  ChangePasswordDto,
  CheckingPasswordDto,
  ResetPasswordDto
} from 'dto';

/**
 * Repository related User's Auth
 */
const AuthRepository = {
  async login(email: string, password: string) {
    const result: AuthDTO = (
      await http.post<AuthDTO>('admin/auth/login', { email, password })
    ).data;
    return result;
  },

  async changePassword(changePassword: ChangePasswordDto): Promise<void> {
    const res = await http.put('/auth/password', changePassword);
    return res.data;
  },

  async verifyTwoFA(code: string, email: string): Promise<any> {
    const { data } = await http.post('admin/auth/verify-2fa', { code, email });
    if (data?.accessToken) {
      localStorage.setItem('token', data?.accessToken);
    }
    return data;
  },

  async loginByTwoFa(email: string): Promise<any> {
    const { data } = await http.post('admin/auth/login-2fa', { email });
    return data;
  },

  async forgotPassword(email: string): Promise<void> {
    const res = await http.post('/admin/auth/password/forgot', { email });
    return res.data;
  },

  async resetPassword(resetPasswordDto: ResetPasswordDto): Promise<void> {
    const res = await http.post('/admin/auth/password/reset', resetPasswordDto);
    return res.data;
  },

  async checkingOptForgotPassword(
    checkingPasswordDto: CheckingPasswordDto
  ): Promise<void> {
    const res = await http.post(
      '/admin/auth/password/checking-otp',
      checkingPasswordDto
    );
    return res.data;
  },
  async resendOtpPassword(email: string): Promise<any> {
    const res = await http.post('admin/auth/password/resend-otp', { email });
    return res.data;
  }
};

export default AuthRepository;
