import { Box } from '@mui/material';
import axios from 'axios';
import { ISingleBlock } from 'dto';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ImageGallery from 'src/common/Mui/ImageGallery';
import { DISPLAY_TYPE } from 'src/content/hook-form/constants';

function Preview() {
  const { id, token } = useParams();

  const [singleBlock, setSingleBlock] = useState<ISingleBlock | undefined>(
    undefined
  );

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }, []);

  const renderImgText = (preview: ISingleBlock) => {
    return (
      <Box width="100%" height="100%" padding="20px">
        <Box mb={4}>
          <ImageGallery src={preview?.image} unUseBorder />
        </Box>
        <div dangerouslySetInnerHTML={{ __html: preview?.content }} />
        <Box mt={4}>
          {preview?.video && (
            <>
              <iframe width="100%" height="315" src={preview.video}></iframe>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const renderTextImg = (preview: ISingleBlock) => {
    return (
      <Box width="100%" height="100%" padding="20px">
        <div dangerouslySetInnerHTML={{ __html: preview?.content }} />
        <Box mt={4}>
          <ImageGallery src={preview?.image} unUseBorder />
        </Box>
        <Box mt={4}>
          {preview?.video && (
            <>
              <iframe width="100%" height="315" src={preview.video}></iframe>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const fetchContentOfLessonById = async (id: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/cards/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSingleBlock(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchContentOfLessonById(Number(id));
    }
  }, [id]);
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ overflowX: 'hidden' }}
      bgcolor="#fff"
    >
      <Box width="100%" height="100%">
        {singleBlock?.displayType === DISPLAY_TYPE?.[0].value
          ? renderImgText(singleBlock)
          : singleBlock?.displayType === DISPLAY_TYPE?.[1].value
          ? renderTextImg(singleBlock)
          : ''}
      </Box>
    </Box>
  );
}

export default Preview;
