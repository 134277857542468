import { FilterUserParams, User, UpdateUserDto } from 'src/common/types';
import http from '../util/http';

const UserRepository = {
  getProfile: async (): Promise<User> => {
    const { data } = await http.get('/auth/me');

    return data as User;
  },

  async getAll(params: FilterUserParams) {
    const { data } = await http.get('/admin/users', { params });

    return {
      users: data.data as Array<User>,
      total: data.count as number
    };
  },

  async getAllToExport(params: FilterUserParams) {
    const { data } = await http.get('/admin/users/export', { params });

    return {
      users: data.data as Array<User>,
      total: data.count as number
    };
  },

  async get(id: number): Promise<User> {
    const { data } = await http.get(`/admin/users/${id}`);

    return data;
  },

  async updateUser(id: number, payload: Omit<UpdateUserDto, 'families'>) {
    const { data } = await http.put(`/admin/users/${id}`, payload);

    return data;
  },

  async deleteUser(id: number): Promise<void> {
    await http.delete(`/admin/users/${id}`);
  }
};

export default UserRepository;
