import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  styled
} from '@mui/material';
import AuthRepository from 'src/common/repository/AuthRepository';
import { FormProvider, RHFTextField } from 'src/components/HookForm';
import useValidation from './useValidation';
import { ChangePasswordDto } from 'dto';

const MainContent = styled(Box)(
  ({ theme }) => `
        height: 100%;
        display: flex;
        flex: 1;
        overflow: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 500px;
    `
);
const ButtonContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(4)}px;
  `
);

function ChangePasswordModal({ onCancel }: { onCancel: () => void }) {
  const { methods } = useValidation();
  const {
    handleSubmit,
    formState: { isSubmitting, errors }
  } = methods;

  const submit = (data: ChangePasswordDto) => {
    const { password, oldPassword } = data;
    AuthRepository.changePassword({
      password,
      oldPassword
    }).then((res) => {
      onCancel();
    });
  };

  return (
    <MainContent>
      <Container maxWidth="md">
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
          <Box textAlign="center">
            <Typography variant="h2" sx={{ my: 2 }}>
              Change Password
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <RHFTextField
              label="Old password *"
              placeholder="Old password *"
              className="w-full"
              name="oldPassword"
              type="password"
            />
            <div className="mt-4"></div>
            <RHFTextField
              label="New password *"
              placeholder="New password *"
              className="w-full"
              name="password"
              type="password"
            />
            <div className="mt-4"></div>
            <RHFTextField
              label="Confirmed password *"
              placeholder="Confirmed password *"
              className="w-full"
              name="confirmPassword"
              type="password"
            />
            <Divider sx={{ my: 4 }} />

            <ButtonContainer>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
            </ButtonContainer>
          </Container>
        </FormProvider>
      </Container>
    </MainContent>
  );
}

export default ChangePasswordModal;
