import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import 'react-quill/dist/quill.snow.css';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import NotistackProvider from './components/NotistackProvider';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <NotistackProvider>{content}</NotistackProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
