import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useEffect,
  useReducer
} from 'react';
import UserRepository from 'src/common/repository/UserRepository';
import { User } from 'src/common/types';

interface AuthState {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  user?: User | null;
}

interface PayloadAction<T> {
  type: 'INITIALIZE' | 'SIGN_IN' | 'SIGN_OUT';
  payload: T;
}

interface HandlerState {
  INITIALIZE: (state: AuthState, action: PayloadAction<AuthState>) => AuthState;
  SIGN_IN: (state: AuthState, action: PayloadAction<AuthState>) => AuthState;
  SIGN_OUT: (state: AuthState) => AuthState;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: HandlerState = {
  INITIALIZE: (
    state: AuthState,
    action: PayloadAction<AuthState>
  ): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },

  SIGN_IN: (state: AuthState, action: PayloadAction<AuthState>): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },

  SIGN_OUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: AuthState, action: PayloadAction<AuthState>) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthProviderProps {
  children: ReactNode;
}

type ContextType = AuthState & {
  signOut: () => void;
  dispatch: Dispatch<PayloadAction<AuthState>>;
};

const AuthContext = createContext<ContextType>({
  ...initialState,
  signOut: () => {},
  dispatch: () => {}
});

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  function initialize(isAuthenticated?: boolean, user?: User) {
    dispatch({
      type: 'INITIALIZE',
      payload: { isAuthenticated, user }
    });
  }

  function signOut() {
    window.localStorage.removeItem('token');
    dispatch({ type: 'SIGN_OUT', payload: { user: null } });
  }

  useEffect(() => {
    async function getProfile() {
      const token = window.localStorage.getItem('token');
      if (!token) return initialize();
      try {
        const user = await UserRepository.getProfile();

        initialize(true, user);
      } catch {
        initialize();
      }
    }
    getProfile();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, signOut, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
