import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from 'react';
import isString from 'lodash/isString';
import { Avatar, SxProps, Theme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddAPhotoRounded, Image } from '@mui/icons-material';

// ────────────────────────────────────────────

const RootStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500]}`
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.default,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': { opacity: 0.72 }
}));

// ────────────────────────────────────────────

export interface UploadAvatarProps {
  error: boolean;
  size?: number;
  src: string;
  helperText?: ReactNode;
  cleaned?: boolean;
  sx?: SxProps<Theme>;
  onSelect?(file?: File): void;
}

export const UploadAvatar: FC<UploadAvatarProps> = ({
  error,
  src,
  helperText,
  sx,
  onSelect,
  cleaned = true,
  size = 144,
  ...other
}: UploadAvatarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string>();

  function handleSelect(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files[0];

    if (!file) return;

    const blob = URL.createObjectURL(file);
    setPreview(blob);
    onSelect?.(file);
    event.target.value = '';
  }

  useEffect(() => {
    return () => {
      if (!preview || !cleaned) return;

      URL.revokeObjectURL(preview);
    };
  }, [cleaned, preview]);

  return (
    <>
      <RootStyle
        sx={{
          ...(error && {
            borderColor: 'error.main'
          }),
          width: size,
          height: size,
          ...sx
        }}
        onClick={() => inputRef.current?.click()}
      >
        <DropZoneStyle>
          <input
            ref={inputRef}
            hidden
            type="file"
            accept="image/*"
            onChange={handleSelect}
          />

          {(preview || src) && (
            <Avatar
              alt="avatar"
              src={preview ?? src}
              sx={{ zIndex: 8, width: size, height: size }}
            />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(preview && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 }
              }),
              ...(error && {
                bgcolor: 'error.main'
              })
            }}
          >
            <AddAPhotoRounded width={24} height={24} />
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>
      {helperText && helperText}
    </>
  );
};
