import { yupResolver } from '@hookform/resolvers/yup';
import { ChangePasswordDto } from 'dto';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export default function useValidation() {
  const formSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required !'),
    password: Yup.string().required('Password is required !'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required !')
  });

  const methods = useForm<ChangePasswordDto>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    }
  });

  return { methods };
}
