import http from '../util/http';
import dto, { bodyKeywordDto, searchDto, LessonsDto, SwapLessonDto } from 'dto';

const LessonsRepository = {
  async getList(params?: searchDto) {
    const result = await http.get('/admin/lessons', {
      params: params || undefined
    });
    return result.data;
  },

  async getById(id: number) {
    const result = await http.get(`/admin/lessons/${id}`);
    return result.data;
  },

  async create(body: LessonsDto) {
    const result: LessonsDto = await http.post<LessonsDto>(
      '/admin/lessons',
      body
    );
    return result;
  },

  async update(id: number, body: LessonsDto) {
    const result: LessonsDto = await http.put<LessonsDto>(
      `/admin/lessons/${id}`,
      body
    );
    return result;
  },

  async delete(id: number) {
    const result = await http.delete(`/admin/lessons/${id}`);
    return result.data;
  },

  async swap(body: SwapLessonDto) {
    const result: SwapLessonDto = await http.put<SwapLessonDto>(
      '/admin/lessons/swap',
      body
    );
    return result;
  },
};

export default LessonsRepository;
