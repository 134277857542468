import axios from 'axios';
import qs from 'qs';
import { AmsHttp } from 'ams';
import { ValueOfContType } from 'dto';

export const ignoreErrorCode = ['userId', 'email', 'menuId'];

const instance: AmsHttp = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 1000 * 60 * 5,
  // withCredentials: true,
  paramsSerializer: (params) =>
    qs.stringify(params, {
      allowDots: true,
      indices: true,
      skipNulls: true,
      arrayFormat: 'repeat'
    })
});
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    Object.assign(config, {
      headers: {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`
      }
    });
  }
  return config;
});

/**
 * New http with typescript
 * For detail, check `AmsHttp` in ams.d.ts
 * - get
 * - delete
 * - post
 * - put
 * - multipart
 * - download
 */
const http: AmsHttp & Required<Pick<AmsHttp, 'multipart' | 'download'>> =
  Object.assign({}, instance, {
    multipart<T>(url: string, data: FormData) {
      return instance.post<T, FormData>(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=----myboundary'
        }
      });
    },
    async download(
      items: {
        contId: number;
        contType: ValueOfContType;
        refMediaSeq?: number;
      }[]
    ) {
      return instance
        .post<BlobPart, false>(`/storage/content/download`, items, {
          responseType: 'blob',
          timeout: 1000 * 60 * 10
        })
        .then((response) => {
          // ex. content-disposition: "attachment;filename=schedule_20220406105241.zip"
          const disposition = response.headers['content-disposition'];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          const filename = (matches?.[1] || '').replace(/['"]/g, '');
          const blob = new Blob([response.data as BlobPart]);

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();

          return response;
        });
    },
    async downloadPDF(originPath: string, nameFile: string) {
      return instance
        .get<BlobPart, false>(`${originPath}`, {
          responseType: 'blob'
        })
        .then((response) => {
          // ex. content-disposition: "attachment;filename=schedule_20220406105241.zip"
          const blob = new Blob([response.data as BlobPart], {
            type: 'application/pdf'
          });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = nameFile;
          link.click();

          return response;
        });
    },

    async downloadImage(originPath: string, nameFile: string) {
      return instance
        .get<BlobPart, false>(`${originPath}`, {
          responseType: 'blob'
        })
        .then((response) => {
          // ex. content-disposition: "attachment;filename=schedule_20220406105241.zip"
          const blob = new Blob([response.data as BlobPart]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = nameFile;
          link.click();

          return response;
        });
    }
  });

export default http;
