import { FieldValues, FormProvider as RHFormProvider } from 'react-hook-form';

import { FormProviderProps } from './hook-form.types';

export function FormProvider<T extends FieldValues>({
  children,
  onSubmit,
  methods,
  style
}: FormProviderProps<T>) {
  return (
    <RHFormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        encType="multipart/form-data"
        style={{ height: '100%', overflow: 'auto', ...style }}
      >
        {children}
      </form>
    </RHFormProvider>
  );
}
