export const GENDER_OPTION = ['Men', 'Women', 'Kids'];

export const STATUS_OPTIONS = ['paid', 'unpaid', 'overdue', 'draft'];

export const DISPLAY_TYPE = [
  {
    key: 'Image first - Header / text body',
    value: 'image_text'
  },
  {
    key: 'Header / text body - image',
    value: 'text_image'
  },
  {
    key: 'Quiz',
    value: 'quiz'
  }
];
