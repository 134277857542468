import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup
} from '@mui/material';
import { RHFRadioGroupProps } from './hook-form.types';

export function RHFRadioGroup({
  name,
  options,
  getOptionLabel,
  ...other
}: RHFRadioGroupProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other}>
            {options.map((option, index) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={
                  getOptionLabel?.length > 0 ? getOptionLabel[index] : option
                }
              />
            ))}
          </RadioGroup>

          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </div>
      )}
    />
  );
}
