import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, ButtonProps, styled } from '@mui/material';

interface ImageGalleryProps {
  image: string;
  onDelete: () => void;
  useDelete: boolean;
  unUseBorder: boolean;
}

export const ActionButton = styled(Button, {
  shouldForwardProp: () => true
})<ButtonProps>(({ disabled }) => ({
  padding: 0,
  display: disabled ? 'none' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  zIndex: 1,
  width: 21,
  height: 21,
  minWidth: 21,
  minHeight: 21,
  borderRadius: 3,
  opacity: 0.8,
  '> svg': {
    fontSize: '15px',
    marginLeft: `0 !important;`
  }
}));

function ImageBox({
  image,
  onDelete,
  useDelete,
  unUseBorder
}: ImageGalleryProps) {
  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      bgcolor="rgba(0, 0, 0, 0)"
      border={unUseBorder ? 'none' : '1px solid rgb(176, 177, 252)'}
    >
      <CardMedia
        component="img"
        alt={`Image`}
        height="100%"
        image={image}
        sx={{ height: '100% !important', objectFit: 'contain' }}
        loading="lazy"
      />
      {useDelete && (
        <CardContent>
          <ActionButton
            color="info"
            variant="contained"
            sx={{
              top: 5,
              right: 5
            }}
            onClick={(e) => {
              e.stopPropagation();
              onDelete?.();
            }}
          >
            <CloseIcon />
          </ActionButton>
        </CardContent>
      )}
    </Box>
  );
}

export default function ImageGallery({
  src,
  useDelete,
  onChange,
  unUseBorder
}: {
  src: string;
  useDelete?: boolean;
  onChange?: () => void;
  unUseBorder?: boolean;
}) {
  return (
    <ImageBox
      image={src}
      onDelete={onChange}
      useDelete={useDelete}
      unUseBorder={unUseBorder}
    />
  );
}
