import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

import { useAuth } from 'src/hooks';
interface AuthGuardProperties {
  children: ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProperties) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string>('');

  if (!isInitialized) return <SuspenseLoader />;

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Navigate to="/login" replace />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation('');

    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
